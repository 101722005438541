import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import RequestCertificateComponent from "../../../components/Certificate/request"
import Layout from "../../../components/layout"
import Spinner from "../../../components/Reusable/Spinner"
import SEO from "../../../components/seo"
import {
  getCourseCertificateByCourseId,
  getCourseProgress,
  getCourseById,
} from "../../../services/course"

import * as sessionSelectors from "../../../redux/slices/session/selectors"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const RequestCertificatePage = ({ user, params }) => {
  const [loading, setLoading] = useState(true)
  const [course, setCourse] = useState(null)

  useEffect(() => {
    getCourseProgress(params.course_id).then(resp => {
      if (resp.data?.percentage !== 100) {
        navigate(`/course/${params.course_id}`)
      }
    })

    getCourseCertificateByCourseId(params.course_id)
      .then(resp => {
        if (resp.data?.id) navigate(`/certificate/${resp.data.id}`)
      })
      .catch(err => {
        if (err.response.status === 404) setLoading(false)
      })

    getCourseById(params.course_id).then(resp => {
      setCourse(resp.data)
    })
  }, [])

  return (
    <Layout>
      <SEO title="Request Sertifikat" />
      {loading && !course ? (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <RequestCertificateComponent user={user} course={course} />
      )}
    </Layout>
  )
}

export default withConnect(RequestCertificatePage)
