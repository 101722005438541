import React, { useState } from "react"
import { Container } from "./styles"
import Button from "../Reusable/Button"
import Input from "../Reusable/Input"
import { createCourseCertificate } from "../../services/course"
import { toast } from "react-toastify"
import { navigate } from "gatsby"

const RequestCertificateComponent = ({ user, course }) => {
  const [currentName, setCurrentName] = useState(user.display_name)

  const handleSubmit = () => {
    createCourseCertificate({
      user: user.id,
      course: course.id,
      name: currentName,
    })
      .then(resp => {
        navigate(`/certificate/${resp.data.id}`)
      })
      .catch(() => toast.error("Gagal mengirim request"))
  }

  return (
    <Container>
      <div className="left-container">
        <h3>Request Pembuatan Sertifikat</h3>
        <div>
          Perhatian! Nama yang akan dimasukkan ke dalam sertifikat tidak akan
          bisa diubah. Pastikan nama yang anda masukkan sudah benar.
        </div>
        <br />
        <div>
          Nama anda
          <Input
            value={currentName}
            onChange={e => setCurrentName(e.target.value)}
          />
        </div>
        <br />
        <div>
          Kursus
          <Input value={course?.title} disabled />
        </div>
        <br />
        <Button disabled={!currentName} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      <div className="right-container"></div>
    </Container>
  )
}

export default RequestCertificateComponent
